/* @font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
 */
@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter.ttf) format("truetype");
}
